@import '@root/05-components/swatch';

$swatch-selected-padding: rem-calc(4);
$swatch-selected-round-padding: rem-calc(6);
$swatch-selected-image-width: rem-calc(20);
$swatch-selected-image-height: rem-calc(20);

@mixin ysljp-layer-components-swatch {
    .c-swatches .c-carousel__item {
        .c-swatch {
            padding: $swatch-selected-padding;
            border-radius: 0;
            background-clip: content-box;
        }

        .c-swatch.m-small-for-large {
            @include breakpoint(medium down) {
                min-width: $swatch-selected-image-width;
                width: $swatch-selected-image-width;
                height: $swatch-selected-image-height;
            }
        }
    }

    .c-swatches .c-swatch.m-large-for-large {
        padding: $swatch-selected-round-padding;
        border-radius: 0;
    }
}
