// Empty minicart
$minicart-empty-text-font: 500 #{rem-calc(30)} / 1 $font-primary;
$minicart-empty-checkout-link-font: 500 #{rem-calc(14)} / 1 $font-primary;
$minicart-empty-text-margin: rem-calc(0 0 55);
$minicart-empty-content-background: color(element-background);
$minicart-empty-background: color(global-background);
$minicart-footer-content-background: color(global-background);
$minicart-footer-content-box-shadow: rem-calc(2 -2 6 0) rgba(color(element-background), 0.1);

// Table minicart
$minicart-background: transparent;
$minicart-th-font: 500 #{rem-calc(24)} / 1 $font-primary;
$minicart-th-details-font: 500 rem-calc(24) $font-primary;
$minicart-th-qty-font: 500 #{rem-calc(14)} / 1 $font-primary;
$minicart-th-price-font: 500 #{rem-calc(14)} / 1 $font-primary;
$minicart-th-padding: rem-calc(30 15 15 30);
$minicart-th-price-text-align: right;
$minicart-td-padding: rem-calc(20 15 10);
$minicart-td-price-padding: rem-calc(20 15 10);
$minicart-td-qty-font: 500 rem-calc(18) $font-primary;
$minicart-footer-border: 0;
$minicart-header-border: 1px solid color(border);
$minicart-items-background: color(global-background);
$minicart-item-content-padding: rem-calc(40 25 20 20);

// Minicart Product
$minicart-product-title-font: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$minicart-product-title-text-transform: uppercase;
$minicart-product-description-text-transform: uppercase;

// Minicart Actions
$minicart-actions-background: color(element-background);

// Minicart Subtotal
$minicart-subtotal-background: color(element-background);
$minicart-subtotal-title-text-transform: uppercase;
$minicart-subtotal-price-text-align: right;

// Product Remove Icon
$minicart-remove-text-transform: uppercase;
$minicart-remove-font: 500 rem-calc(10) / 1 $font-primary;
$minicart-remove-icon-display: false;

$minicart-subtotal-title-font: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$minicart-subtotal-price-font: $minicart-subtotal-title-font;
$minicart-width: rem-calc(400);

@import "@lora/05-components/minicart";