@import '@root/05-components/checkout/checkout-payment';

/* stylelint-disable */
.checkout_section_content {
    .chmodule_paymentmethod {
        .select_payment_method {
            .f_field_radio {
                > label {
                    position: relative;
                }
            }
        }
    }

    .chmodule_shippingmethod {

        .checkout_module_body,
        .hazmat_message {
            width: 100%;
            float: left;

            .required {
                .f_label {
                    .description,
                    .price {
                        width: auto;
                    }
                }
            }
        }
    }

    .order_summary {
        .order_summary_cartedit_btn {
            color: color(dark);
            font-size: rem-calc(11);
        }
    }
}