$loyalty-load-more-button-color: color(light);

@mixin ysljp-layer-loyalty-load-more {
    .c-load-more__button {
        &:hover,
        &:focus,
        &:active {
            @include breakpoint(medium down) {
                color: $loyalty-load-more-button-color;
            }
        }
    }
}
