@import "@root/05-components/product/product-tile";

$product-tile-swatch-selected-font-size--large: rem-calc(10);
$product-tile-thumbnail-overflow: visible;
$product-tile-thumbnail-margin: rem-calc(0 0 34);

@include breakpoint(large) {
    .c-product-tile__actions {
        .c-product-add-bag__button {
            min-width: 100%;
        }
    }
}
