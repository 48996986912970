$loyalty-refinements-category-refinement-order: null !default;
$loyalty-refinements-price-refinement-order: null !default;
$loyalty-refinements-sorting-refinement-order: null !default;
$loyalty-refinements-tier-refinement-order: null !default;

@mixin apaclayer-components-loyalty-refinements-page {
    .c-loyalty-refinements {
        .c-loyalty-refinements__category-refinement {
            order: $loyalty-refinements-category-refinement-order;
        }

        .c-loyalty-refinements__price-refinement {
            order: $loyalty-refinements-price-refinement-order;
        }

        .c-loyalty-refinements__sorting-refinement {
            order: $loyalty-refinements-sorting-refinement-order;
        }

        .c-loyalty-refinements__tier-refinement {
            order: $loyalty-refinements-tier-refinement-order;
        }
    }
}
