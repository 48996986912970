@import '@root/05-components/checkout/checkout-common';

/* stylelint-disable */
.checkout_section {
    .checkout_module {
        clear: none;

        @include breakpoint(large) {
            padding: rem-calc(20 0);
        }
    }

    .checkout_section_title.js_checkout_section_title {
        margin-bottom: 15px;

        &:before {
            counter-increment: sections;
            content: counter(sections) ".";
        }
    }

    &.chsection_login.completed {
        .checkout_section_title {
            display: block;
            margin-bottom: 15px;
        }

         .signin_placeholder_parent {
            flex-direction: column;

            .checkout_module_body {
                padding: rem-calc(18);
                align-self: start;
            }
        }
    }

    &.completed {
        .checkout_module_address {
            border: none;
        }
    }

    .button[disabled] {
        background-color: #ccc;
        border: 1px solid transparent;
        color: #fff;
        cursor: not-allowed;
        pointer-events: none;
    }

    .l-checkout-authentification {
        @include grid;

        margin-left: 0;
        margin-right: 0;

        .l-checkout-authentification__item {
            &.m-signin {
                order: 1;
            }

            &.m-guest {
                order: 3;

                @include breakpoint(medium down) {
                    margin-top: rem-calc(30);
                }
            }

            &.m-registration {
                order: 2;
            }
        }

        .c-account__title,
        .c-checkout-authentification__title {
            font-size: rem-calc(22);
            padding-bottom: rem-calc(10);
        }

        .c-checkout-authentification__description {
            min-height: auto;

            @include breakpoint(large) {
                min-height: rem-calc(75);
            }
        }
    }

    &.checkout_section_signin {
        .checkout_section_title {
            color: #000;
        }
    }
}

.checkout_step_onestepcheckout {
    .checkout_static_steps {
        .checkout_sections {
            counter-reset: sections !important;

            .checkout_section_title.js_checkout_section_title {
                &:before {
                    counter-increment: sections !important;
                    content: counter(sections)"." !important;
                }
            }
        }
    }
}

.js_checkout_steps .checkout_section_title::before {
    counter-increment: section !important;
    content: counter(section)"." !important;
}

.js_checkout_steps .checkout_section.checkout_static_steps {
    counter-reset: section !important;
}

.chmodule_samples {
    .samples_add_button,
    .samples_remove_button {
        padding: 0;
        width: 100%;
    }

    .selected_samples_message {
        width: auto;
    }
}

 .checkout_section {
     .checkout_module {
         clear: none;

         @include breakpoint(large) {
             padding: rem-calc(20 0);
         }
     }
 }

 .chmodule_newsletter {
     .subscriptions_area {
         border-bottom: none;
     }
 }

.checkout_module_address {
    clear: both;

    .checkout-address-fields.f_field,
    .register_policy.form_caption,
    .marketing_comunication,
    .formfield_example_phone {
        clear: both;
    }

    .f_field {
        width: 100%;

        @include breakpoint(large) {
            margin: rem-calc(10 14 4 0);
            width: 48%;
        }
    }


    .register_policy.form_caption {
        padding: rem-calc(25 0 15);
    }

    .formfield_example_zip,
    .formfield_example_city,
    .formfield_example_address1,
    .formfield_example_address2,
    .formfield_example_phone {
        clear: both;
        font-size: 12px;
        padding: rem-calc(10 0);

        @include breakpoint(large) {
            padding: rem-calc(15 30);
            clear: none;
            float: left;
        }
    }

    .formfield_district,
    .formfield_email_confirm {
        padding: rem-calc(20 0);
    }

    .formfield_example_zip,
    .formfield_example_city {
        margin-top: rem-calc(30);

        @include breakpoint(medium down) {
            float: left;
            margin-top: 0;
        }
    }

    .marketing_comunication p {
        padding-top: 5px;
    }

    .formfield_example_phone {
        padding-bottom: rem-calc(10);
        margin: rem-calc(30 0 4 14);

        @include breakpoint(medium down) {
            float: left;
            margin-top: 0;
        }
    }
}

.order_summary_header {
    margin: rem-calc(-10 -10 0);
    padding: rem-calc(15 10);
    border-bottom: none;
    background: transparent;
    font-size: rem-calc(12);
    text-transform: none;

    @include breakpoint(large) {
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 1.5em;
    }
}

.checkout_order_summary {
    .checkout_minicart {
        .product_image_container {
            width: 36%;
        }
    }
}

.payment_method {
    .mini_creditcard {
        margin: 0;
        padding: rem-calc(20 0);
    }
}

.chsection_preview {
    .order_summary {
        @include breakpoint(large) {
            padding: rem-calc(20 0);
        }

        .order_summary_title {
            .order_summary_title_value {
                padding: rem-calc(10 0);
            }
        }
    }
}

.chmodule_shippingmethod {

    .name,
    .price {
        @include breakpoint(medium down) {
            width: 45%;
            display: inline;
        }
    }
}

.samples_list {
    margin-left: 0;
}

.chmodule_samples {
    width: auto;
    min-width: 100%;
    height: auto;
    padding: 0;
}

.checkout_customer_service {
    a {
        color: color(light);
    }
}

.checkout_section {

    .chmodule_paymentmethod,
    .chmodule_samples {
        .checkout_module_title {
            display: none;
        }
    }

    .chmodule_paymentmethod {
        .checkout_module_title.completed {
            float: left;
            width: 45%;
            display: block;
        }

        .selected_payment_method {
            @include breakpoint(medium down) {
                padding: 0;
            }

            .method_name,
            .amount {
                float: right;
                margin: 0;
                text-align: right;
                display: block;
                font-size: rem-calc(14);
                font-weight: 400;
            }
        }
    }
 }

.c-modal .c-ngcheckout__storepickup {
    +.c-separator {
        display: none;

        +.c-ngcheckout-selection {
            display: none;
        }
    }

    &:has(.c-ngcheckout-selection__header) {
        +.c-separator {
            display: block;

            +.c-ngcheckout-selection {
                display: block;
            }
        }
    }
}