$footer-nav-link-level1-font: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$footer-nav-link-level1-font--large: $footer-nav-link-level1-font;
$footer-nav-link-level1-text-transform--large: uppercase;
$footer-nav-link-level2-font: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$footer-nav-link-level2-font--large: normal #{rem-calc(12)} / 1.5 $font-primary;
$footer-nav-link-level2-text-transform: uppercase;
$footer-nav-link-level2-text-transform--large: $footer-nav-link-level2-text-transform;
$footer-nav-link-level2-hover-color: color(secondary);
$footer-nav-link-level1-hover-color: color(secondary);
$footer-nav-list-level1-margin: rem-calc(21 0 24);
$footer-nav-list-level1-margin--large: rem-calc(22 0 24);
$footer-nav-link-level2-padding--large: rem-calc(0 0 11);
$footer-nav-list-level2-margin: rem-calc(0);
$footer-nav-link-level1-letter-spacing: rem-calc(1);
$footer-nav-link-level1-letter-spacing--large: $footer-nav-link-level1-letter-spacing;
$footer-nav-link-level2-letter-spacing: $footer-nav-link-level1-letter-spacing;

@import "@lora/05-components/footer/footer-nav";