@import "@root/05-components/form/form";

$form-account-registration: rem-calc(30 0 0);
$form-account-edit-profile-button-padding: rem-calc(18 5);
$form-account-edit-profile-button: transparent;

@mixin ysljp-layer-components-form {
    .c-form-profile {
        .c-button.m-secondary,
        .c-button.m-secondary:hover {
            padding: $form-account-edit-profile-button-padding;
            background-color: $form-account-edit-profile-button;
        }
    }
}
