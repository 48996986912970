@import "@root/05-components/button";

$button-social-facebook-background: #34599f;
$button-social-line-background: #01b901;
$button-social-twitter-background: #1da1f4;
$button-social-yahoo-background: #fe0132;
$button-social-icon-left: true;
$button-social-icon-size: rem-calc(16);
$button-social-icon-margin: rem-calc(5);

$button-social-icon-text-transform: uppercase;
$button-social-list: (
    facebook: (
        icon: facebook,
        background: $button-social-facebook-background,
    ),
    twitter: (
        icon: twitter,
        background: $button-social-twitter-background,
    ),
    instagram: (
        icon: instagram,
        background: color(primary)
    ),
    google: (
        icon: google,
        background: color(primary)
    ),
    linkedin: (
        icon: linkedin,
        background: color(primary)
    ),
    gmail: (
        icon: gmail,
        background: color(primary)
    ),
    pinterest: (
        icon: pinterest,
        background: color(primary)
    ),
    line: (
        icon: lineme,
        background: $button-social-line-background
    ),
    yahoo: (
        icon: yahoo,
        background: $button-social-yahoo-background
    ),
);
