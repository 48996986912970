$account-table-cell-header-background: color(light);
$account-table-cell-header-font: #{rem-calc(14)} / rem-calc(20) $font-primary;
$account-table-cell-header-text-transform: capitalize;
$account-table-cell-header-padding: rem-calc(10 15);
$account-table-cell-status-font: #{rem-calc(12)} / 1.5 $font-primary;
$account-table-cell-background-hover: color(dark);
$account-table-cell-color-hover: color(secondary);
$account-table-cell-header-color: color(primary);
$account-table-cell-text-shadow-hover: 0 0 1px color(secondary);
$account-table-cell-header-sort-icon-color: color(dark);
$account-table-cell-header-sort-icon-height: rem-calc(9);
$account-table-cell-header-sort-icon-width: rem-calc(14);
$account-table-cell-header-sort-padding: rem-calc(3 30 3 16);
$account-table-cell-header-sort-icon-right: rem-calc(7);
$account-table-cell-background: color(highlight);
$account-table-cell-background--large: $account-table-cell-background;
$account-table-cell-header-height: rem-calc(90);
$account-table-row-box-shadow-hover: none;

///Current
$account-table-cell-header-sort-current-icon-color: color(light);
$account-table-cell-header-sort-current-icon-background-color: color(dark);
$account-table-cell-header-sort-current-icon-height: rem-calc(16);
$account-table-cell-header-sort-current-icon-width: rem-calc(16);
$account-table-cell-date-font--large: 500 #{rem-calc(12)} / 1.5 $font-secondary;
$account-table-cell-header-date-font--large: 500 #{rem-calc(14)} / 1.42 $font-secondary;
$account-table-cell-date-color: color(dark);
$account-table-cell-date-color--large: $account-table-cell-date-color;

@import "@lora/05-components/account/account-table";